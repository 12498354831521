.footer-logo {
    width: 10rem;
    position: absolute;
    bottom: 0;
    right: 0;
}

.icon{
    width: 3rem;
}

.viewer {
    max-width: 100%;
    max-height: 55vh;
    box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important;
}

.gallery-preview {
    max-height: 55vh;
    overflow-x: auto;
}

.preview{
    width: 100%;
    opacity: 0.6;
}

.img-picked{
    opacity: 1 !important;
}

.preview:hover{
    opacity: 0.75;
    box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important;
}

.spinner {
    z-index: -1;
    position: absolute;
    left: calc(50% - 1rem);
    top: calc(50% - 1rem);
}

@media only screen and (max-width: 600px) {
    .gallery-header > .header-logo {
        margin-left: auto !important;
        margin-right: auto !important;
        display: block;
    }

    .footer-logo {
        position: initial;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}