.home-hero {
    color: #fff;
    text-align: center;
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    min-height: 670px;
    flex-direction: column;
}

.home-logo {
    z-index: -1;
    top: 0;
}

.home-footer {
    z-index: -1;
    width: 12rem;
    margin-bottom: 3rem;
}

.home-hero-content {
    color: white;
    font-size: 1.2rem;
}

.home-title {
    font-size: 6rem;
}

.ribbon-label-cut {
    margin-top: -12rem;
}

.ribbon {
    width: calc(50vw + 4rem);
    height: 3rem;
    top: calc(50% - (3rem / 2));
}

.ribbon-and-text-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.ribbon-wrapper {
    width: 100vw;
    height: 10rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ribbon-left {
    transition: all 1s;
    margin-left: -4rem;
    background: rgb(103, 9, 7);
    background: linear-gradient(146deg, rgba(103, 9, 7, 1) 0%, rgba(192, 1, 1, 1) 73%);
    border-bottom-left-radius: 70%;
}

.ribbon-right {
    transition: all 1s;
    margin-right: -4rem;
    background: rgb(192, 1, 1);
    background: linear-gradient(49deg, rgb(192, 1, 1) 20%, rgb(103, 9, 7) 80%);
    border-bottom-right-radius: 70%;
}

.ribbon-left-cut {
    transform: rotate(90deg);
    margin-top: calc(25vw - 4rem);
    margin-left: calc(-25vw - 4rem);
}

.ribbon-right-cut {
    transform: rotate(-90deg);
    margin-top: calc(25vw - 4rem);
    margin-right: calc(-25vw - 4rem);
}

.fade-out {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 2s, opacity 2s linear;
}

.fade-in {
    animation: fadeIn 2s linear;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
