@media (min-width: 768px){
    .me-md-auto {
        margin-right: auto!important;
    }

    .me-md-3 {
        margin-right: 1rem!important;
    }

    .my-md-0 {
        margin-top: 0!important;
        margin-bottom: 0!important;
    }
}

body{
    min-height: 670px;
    background-image: url('./images/bg.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    font-family: Helvetica,Arial,Sans-Serif;
}

.header-logo {
    width: 15rem;
}

.uniform-card {
    height: calc(100% - 1.5rem);
}

.text-brand {
    font-family: 'NeuropoliticalRg-Regular';
}